import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { useParams } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions';
import EdgeToast from '#components/EdgeToast.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

if (process.env.REACT_APP_AG_GRID_LICENSE_KEY) {
    LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
} else {
    console.warn('AG Grid license key not found in environment variables.');
}

const CashbackDetailsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [cashbackTransaction, setCashbackTransaction] = useState({});
    const [cashbackDetails, setCashbackDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    // Get current month and year
    const currentDate = new Date();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Convert month to 2 digits
    const currentYear = String(currentDate.getFullYear());

    // Dropdown state (default to current month & year)
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    // Function to calculate the last day of the selected month
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate(); // Gets the last day of the given month
    };

    // Compute startDate and endDate
    const lastDay = getLastDayOfMonth(selectedYear, selectedMonth);
    const formattedStartDate = `${selectedYear}-${selectedMonth}-01`;
    const formattedEndDate = `${selectedYear}-${selectedMonth}-${String(lastDay).padStart(2, '0')}`;

    // Month and Year options
    const months = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ];

    const years = ['2024', '2025'];

    const columnDefs = [
        {
            headerName: 'Transaction Amount',
            field: 'amt',
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                return value.toFixed(2);
            },
        },
        { headerName: 'Post Timestamp', field: 'post_ts' },
        { headerName: 'Details', field: 'details', flex: 1 },
        { headerName: 'Auth Timestamp', field: 'auth_ts' },
        { headerName: 'MCC', field: 'mcc' },
    ];

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Cashback Details'));
        fetchCashbackDetails();
    }, [user_id]);

    async function fetchCashbackDetails() {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.post(`users/${user_id}/cashback`, {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            if (response.data) {
                setTransactionDetails(response.data.transactionDetails);
                setCashbackDetails({
                    cashback: response.data.cashback,
                    cashbackRate: response.data.cashbackRate,
                    qualifyingTransactionSum: response.data.qualifyingTransactionSum,
                    userTier: response.data.userTier,
                    calculatedTier: response.data.calculatedTier,
                    calculatedRate: response.data.calculatedRate,
                });
                if (response.data.cashbackTransaction) {
                    setCashbackTransaction({
                        paidCashback: response.data.cashbackTransaction.amount,
                        utilizedRate: response.data.cashbackTransaction.cashbackRate,
                        utilizedTier: response.data.cashbackTransaction.cashbackUserTier,
                    });
                } else {
                    setCashbackTransaction({});
                }
            } else {
                showMessageToast('warning', 'No Transactions', 'No cashback transactions found for this user');
            }
        } catch (error) {
            console.error('Could not fetch cashback transactions', error);
            showMessageToast('error', 'Error', 'Failed to fetch Cashback Transactions');
        }
        setIsLoading(false);
    }

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>Cashback Details</title>
            </Helmet>
            <div className="mb-3">
                <button className="btn btn-warning mx-2" onClick={onBackToUserDetails}>
                    Back To User Details
                </button>
            </div>

            <div className="mb-3">
                <div className="card p-3 shadow-sm border-primary">
                    <div className="mb-2">
                        <label className="form-label fw-semibold text-secondary">Select Month & Year</label>
                        <div className="input-group">
                            <select
                                className="form-select form-select-lg border-dark shadow-sm rounded"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}>
                                {months.map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </select>

                            <select
                                className="form-select form-select-lg border-dark shadow-sm rounded"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}>
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="btn btn-warning mt-3" onClick={fetchCashbackDetails}>
                            Run Report
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="card p-3 shadow-sm border-primary">
                    <h4>Current Account Details</h4>
                    <p>
                        <strong>Current Account Tier Level:</strong> {cashbackDetails.userTier} &nbsp;|&nbsp;
                        <strong>Current Account Cashback Rate:</strong> {cashbackDetails.cashbackRate}
                    </p>
                </div>
            </div>
            <div className="mb-3">
                <div className="card p-3 shadow-sm border-primary">
                    <h4>Calculated Cashback Overview</h4>
                    <p>
                        <strong>Total Cashback:</strong> ${parseFloat(cashbackDetails.cashback || 0).toFixed(2)}{' '}
                        &nbsp;|&nbsp;
                        <strong>Sum of Qualifying Transactions</strong> $
                        {parseFloat(cashbackDetails.qualifyingTransactionSum || 0).toFixed(2)}
                        &nbsp;|&nbsp;
                        <strong>Calculated Tier Level:</strong> {cashbackDetails.calculatedTier} &nbsp;|&nbsp;
                        <strong>Calculated Cashback Rate:</strong> {cashbackDetails.calculatedRate}
                    </p>
                </div>
            </div>

            <div className="mb-3">
                <div className="card p-3 shadow-sm border-primary">
                    <h4>Cashback Payment Details</h4>
                    {cashbackTransaction && Object.keys(cashbackTransaction).length > 0 ? (
                        <p>
                            <strong>Total Cashback Payment:</strong> $
                            {(parseFloat(cashbackTransaction.paidCashback || 0) / 100).toFixed(2)}
                            &nbsp;|&nbsp;
                            <strong>Tier Level:</strong> {cashbackTransaction.utilizedTier} &nbsp;|&nbsp;
                            <strong>Cashback Rate Used:</strong> {cashbackTransaction.utilizedRate}
                        </p>
                    ) : (
                        <p>No Cashback Payment found for selected month</p>
                    )}
                </div>
            </div>

            <div>
                {transactionDetails.length > 0 ? (
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact rowData={transactionDetails} columnDefs={columnDefs} />
                    </div>
                ) : (
                    <p>No cashback eligible transactions found</p>
                )}
            </div>
        </div>
    );
};

export default CashbackDetailsPage;
