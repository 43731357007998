import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone'
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions.js';
import EdgeToast from '#components/EdgeToast.js';
import { Table, Badge, Modal, ModalHeader, ModalBody, Card, Button, CardText, Label, Input } from 'reactstrap';
import ConfirmModal from '#components/ConfirmModal.js';
import { GalileoModifyAction } from '#constants/enums.js';

const GalileoCard = ({ cardUrl, freezeInfo }) => {
    if (cardUrl) {
        return (
            <Card className={`view-card mb-0 mr-0`}>
                <img src={cardUrl} alt="Galileo Betting Card" />
                {
                    freezeInfo?.status === 'Frozen' &&
                    <div className="mt-3 text-center">
                        <CardText><strong>Date Frozen:</strong> {freezeInfo.start_date}</CardText>
                        <CardText><strong>Freeze End Date:</strong> {freezeInfo.end_date}</CardText>
                    </div>
                }
            </Card>
        );
    } else {
        return <h2>Loading...</h2>;
    }
};

const GalileoUserDetailsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);
    const [galileoCards, setGalileoCards] = useState([]);
    const [cardFreezeDetails, setCardFreezeDetails] = useState({})
    const [showNumber, setShowNumber] = useState(true);
    const history = useHistory();
    const [confirmModal, setConfirmModal] = useState({ isOpen: false, body: () => null })
    const [cardViewModalOpen, setCardViewModalOpen] = useState(false);
    const [cardUrl, setCardUrl] = useState('');
    const [extendFreezeModal, setExtendFreezeModal] = useState({ isOpen: false, disabled: true })
    const [days, setDays] = useState(1);
    const [endDate, setEndDate] = useState('')

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Galileo Details'));
        getGalileoCards(user_id);
    }, [user_id]);

    async function getGalileoCards() {
        const response = await ebAdminClient.get(`users/${user_id}/galileo-cards`);
        if (response.data) {
            setGalileoCards(response.data);
            console.log('Galileo cards', response.data);
        } else {
            console.log('No Galileo cards found for this user', user_id);
        }
    }

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'Y':
                return 'success';
            case 'L':
                return 'warning';
            case 'N':
                return 'success';
            default:
                return 'success';
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Y':
                return 'Active';
            case 'L':
                return 'Lost/Stolen';
            case 'N':
                return 'Normal';
            default:
                return 'Unknown';
        }
    };

    const handleViewCard = async (cardId, card) => {
        try {
            if (card.freeze_info.status === "Frozen") {
                setCardFreezeDetails(card.freeze_info)
            }
            const response = await ebAdminClient.get(`users/${user_id}/cards/${cardId}/view`);
            console.log('response', response);
            if (response.data?.card_image_url) {
                setCardUrl(response.data.card_image_url.url);
                setCardViewModalOpen(true);
            } else {
                showMessageToast('error', 'Error', 'Unable to load card details');
            }
        } catch (error) {
            console.error('Error fetching card details:', error);
            showMessageToast('error', 'Error', 'Failed to load card details');
        }
    };

    const handleCardStatusChange = async () => {
        switch (confirmModal.type) {
            case 'Unfreeze': {
                await ebAdminClient.post('cards/modify-status', { prn: confirmModal.card.card_id, type: GalileoModifyAction.UNFREEZE_CARD })
                // update state
                await getGalileoCards()
                setConfirmModal({ isOpen: false, body: () => null })
                return
            }

            case 'Freeze': {
                // Freeze for 24 hours.
                await ebAdminClient.post('cards/modify-status', {
                    prn: confirmModal.card.card_id,
                    type: GalileoModifyAction.FREEZE_CARD,
                    days: days || null
                })
                // update state
                await getGalileoCards()
                setConfirmModal({ isOpen: false, body: () => null })
                setExtendFreezeModal({ isOpen: false, disabled: true })
                return;
            }

            default:
                return;
        }
    }

    const handleEndDateChange = (e) => {
        if (e.target.value > 0) {
            setExtendFreezeModal(prevState => ({ ...prevState, disabled: false }));
        } else {
            setExtendFreezeModal(prevState => ({ ...prevState, disabled: true }));

        }
        setDays(e.target.value);
        const newEndDate = moment().add(e.target.value, 'days').format("MMMM Do, YYYY");
        setEndDate(newEndDate);
    }
    const handleExtendFreezeButton = (e) => {
        setConfirmModal(prevState => ({ ...prevState, isOpen: false }))
        setExtendFreezeModal(prevState => {
            return {
                ...prevState,
                isOpen: true,
                disabled: true,
                title: 'Freeze Card (extended)'
            }
        }
        )
    }

    const handleConfirmModal = (e, card) => {
        const type = e.target.innerText;
        const now = new Date();
        const futureDate = new Date(now.getTime() + 24 * 60 * 60 * 1000).toLocaleString() // add 24 hours
        setConfirmModal({
            isOpen: true,
            type,
            title: `${type} Card`,
            body: () => {
                return (
                    <>
                        <p>Are you sure you want to <strong>{type.toUpperCase()}</strong> this card?</p>
                        {type === 'Freeze' ?
                            <>
                                <>
                                    <p>The card will be frozen for <strong>24 hours (until {futureDate})</strong></p>
                                    <div className="text-center">
                                        <Button color="primary" onClick={handleExtendFreezeButton}>Extend Freeze</Button>
                                    </div>
                                </>
                            </>
                            :
                            <></>
                        }
                    </>
                )
            },
            card
        })
    }

    const handleCreatePhysicalCard = async () => {
        showMessageToast('info', 'Processing...', `Adding New Account to ${galileoCards?.customer?.first_name} ${galileoCards?.customer?.last_name}`)
        const resp = await ebAdminClient.post(`users/${user_id}/accounts`, { prn: galileoCards?.accounts?.[0]?.pmt_ref_no });
        // update state
        await getGalileoCards()
        showMessageToast('success', 'Success!', `Account added successfully with new PRN: ${resp.data.prnNumber}`)
    }

    const handleCreateDigitalCard = async () => {
        showMessageToast('info', 'Processing...', `Adding New Digital Card to ${galileoCards?.customer?.first_name} ${galileoCards?.customer?.last_name}`)
        console.log(galileoCards?.accounts?.[0]?.pmt_ref_no)
        const resp = await ebAdminClient.post(`users/${user_id}/add-digital-card`, { prn: galileoCards?.accounts?.[0]?.pmt_ref_no });
        // update state
        await getGalileoCards()
        showMessageToast('success', 'Success!', `Account added successfully with new PRN: ${resp.data.prnNumber}`)
    }

    const handleActivateCard = (cardId) => {
        console.log("Card to activate: ", cardId)
    }

    const getCardType = (prodId) => {
        switch (prodId) {
            case '1975':
                return 'Digital Only';
            case '3376':
                return 'Digital First';
            default:
                return;
        }
    }

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>Galileo Details</title>
            </Helmet>
            {/* Enhanced User Details Section */}
            <Card className="mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2 className="mb-0">Galileo Details</h2>
                        <button className="btn btn-warning" onClick={onBackToUserDetails}>
                            Back To User Details
                        </button>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Full Name</h6>
                            <h5>
                                {galileoCards?.customer?.first_name} {galileoCards?.customer?.last_name}
                            </h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">PRN</h6>
                            <h5>{galileoCards?.accounts?.[0]?.pmt_ref_no || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Account Number</h6>
                            <h5>{galileoCards?.accounts?.[0]?.galileo_account_number || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Total Cards</h6>
                            <h5>
                                {galileoCards?.accounts?.reduce((total, account) => total + account.cards.length, 0) ||
                                    0}
                            </h5>
                        </div>
                    </div>
                    <Button outline color={"primary"} className="mb-2 mr-3" onClick={handleCreateDigitalCard}>Create Digital Card</Button>
                    <Button color={"primary"} className="mb-2" onClick={handleCreatePhysicalCard}>Create Physical Card</Button>
                </div>
            </Card>
            <h2 className="text-center">View Shared Accounts</h2>
            {/* Cards Table Section */}
            {galileoCards?.accounts?.map((account) => (
                <div key={account.pmt_ref_no} className="mt-4">
                    <div>
                        <h4>Account PRN #{account.pmt_ref_no} </h4>
                    </div>
                    <Table striped responsive className="mb-2">
                        <thead>
                            <tr>
                                <th>Card Number</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {account.cards.map((card) => (
                                <tr key={card.card_id}>
                                    <td>{card.card_number}</td>
                                    <td>{getCardType(account.product_id)}</td>
                                    <td>
                                        {card.freeze_info?.status === 'Frozen' ?
                                            <Badge className="p-1" color={'info'} style={{ color: 'white', fontSize: '1em' }}>
                                                Frozen
                                            </Badge>
                                            :
                                            <Badge className="p-1" style={{ color: 'white', fontSize: '1em' }} color={getStatusBadgeColor(card.card_status)}>
                                                {getStatusLabel(card.card_status)}
                                            </Badge>}
                                    </td>
                                    <td>{account.start_date}</td>
                                    <td className="text-center">
                                        {process.env.REACT_APP_SIMULATOR === "true" &&
                                            <button onClick={() => history.push(`/simulator/galileo?prn=${account.pmt_ref_no}`)} className="btn btn-sm btn-secondary mr-2">
                                                Go to Simulator
                                            </button>
                                        }
                                        {
                                            account.card_status === "Y" ?
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => handleActivateCard(card.card_id)}>
                                                    Activate Card
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => handleViewCard(card.card_id, card)}>
                                                    View Card
                                                </button>
                                        }
                                        {
                                            card.freeze_info?.status === 'Frozen' ?
                                                <button
                                                    className="btn btn-sm btn-danger ml-2"
                                                    onClick={(e) => handleConfirmModal(e, card)}>
                                                    Unfreeze
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-sm btn-info ml-2"
                                                    onClick={(e) => handleConfirmModal(e, card)}>
                                                    Freeze
                                                </button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}

            {/* Card View Modal */}
            <Modal isOpen={cardViewModalOpen} toggle={() => setCardViewModalOpen(false)}>
                <ModalHeader toggle={() => setCardViewModalOpen(false)}>Card Details</ModalHeader>
                <ModalBody>
                    <GalileoCard
                        cardUrl={cardUrl}
                        issueCard={{ cardStatus: 'Y' }}
                        showNumber={showNumber}
                        setShowNumber={setShowNumber}
                        freezeInfo={cardFreezeDetails}
                    />
                </ModalBody>
            </Modal>
            {/* Confirm for Freezing/Unfreezing */}
            <ConfirmModal
                isOpen={confirmModal.isOpen}
                onClose={() => setConfirmModal({ ...confirmModal, isOpen: false })}
                onYes={handleCardStatusChange}
                modalTitle={confirmModal.title}
            >
                {confirmModal?.body()}
            </ConfirmModal>
            <ConfirmModal
                isOpen={extendFreezeModal.isOpen}
                onClose={() => setExtendFreezeModal({ ...extendFreezeModal, isOpen: false })}
                onYes={handleCardStatusChange}
                modalTitle={extendFreezeModal.title}
                disabled={extendFreezeModal.disabled}
            >
                <>
                    {endDate && <p>The card will be frozen for <strong>{days} day(s) (until {endDate})</strong>.</p>}
                    <Label for="days">Days: </Label>
                    <Input type="number" name="days" id="days" onChange={handleEndDateChange} />
                </>
            </ConfirmModal>
            {/* Toast Notifications */}
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    );
};

export default GalileoUserDetailsPage;
